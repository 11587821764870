import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
	NbAuthComponent,
	NbLoginComponent,
	NbLogoutComponent,
	NbRegisterComponent,
	NbRequestPasswordComponent,
	NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthenticationGuard } from './@core/guard/authentication.guard';

export const routes: Routes = [
	{
		path: 'pages',
		loadChildren: () => import('./pages/pages.module')
			.then(m => m.PagesModule),
		canActivate:[AuthenticationGuard]
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
	},
	{ path: '', redirectTo: 'pages', pathMatch: 'full' },
	{ path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
