import { Router } from '@angular/router';
import { LocalStorageService } from './../@resources/services/local-storage.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'environments/environment';
import { NbToastrService } from '@nebular/theme';
import { onError } from 'apollo-link-error';

const uri = environment.url;

export function createApollo(httpLink: HttpLink, toasterService: NbToastrService, storage: LocalStorageService,router:Router) {
	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.map(({ extensions, locations, path }) => {
				if (graphQLErrors) {
					if (graphQLErrors[0].extensions.exception.response.message) {

						if (Array.isArray(graphQLErrors[0].extensions.exception.response.message)) {
							const errArr = [...graphQLErrors[0].extensions.exception.response.message];
							errArr.forEach(element => {
								toasterService.show(element, `Error`, { status: 'danger' });
								
							});
						} else {
							toasterService.show(graphQLErrors[0].extensions.exception.response.message, `Error`, { status: 'danger' });
							if(graphQLErrors[0].extensions.exception.response.message == "Invalid token"){
								localStorage.clear();
								router.navigate(['./auth/login'])
							}
						}
					} else {
						toasterService.show(networkError, `Error`, { status: 'danger' });
					}
				}
			});
	});

	const defaultOptions: DefaultOptions = {
		watchQuery: {
		  fetchPolicy: 'no-cache',
		  errorPolicy: 'ignore',
		},
		query: {
		  fetchPolicy: 'no-cache',
		  errorPolicy: 'all',
		},
	  }
	const basic = setContext((operation, context) => ({
		headers: {
			Accept: 'charset=utf-8'
		}
	}));

	const auth = setContext((operation, context) => {
		const token = storage.get('ADMIN_TOKEN');
		if (token === null) return {}; else return { headers: { Authorization: `Bearer ${token}` } };
	});

	const link = ApolloLink.from([basic, auth, (errorLink as unknown) as ApolloLink, httpLink.create({ uri })]);
	const cache = new InMemoryCache();
	return {
		link,
		cache,
		defaultOptions: defaultOptions,
	}
}

@NgModule({
	exports: [
		HttpClientModule,
	],
	providers: [{
		provide: APOLLO_OPTIONS,
		useFactory: createApollo,
		deps: [HttpLink, NbToastrService, LocalStorageService,Router]
	}]
})
export class GraphQLModule { }
